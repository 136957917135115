export const priceData = [
    {
        // icon: "/images/icons/thumbs-up-white.svg",
        // offer: "Give it a crack",
        title: "Mates Rates",
        description: "up to 5 members",
        price: "$0",
        size: "2GB Storage included",
        monthly: "",
        monthlyFrequency: "more info",
        yearlyFrequency: "more info",
        benefitOne: "Unlimited Chats & Messages",
        benefitTwo: "Unlimited Clips (40 slides)",
        benefitThree: " 3 x To-do lists",
        benefitFour: "Unlimited Tasks",
        benefitFive: "1 x Admin role",
        benefitSix: "In-app & email support",
        benefitSeven: "",
        monthlyUrl: "https://app.gotradie.com.au/signup",
        yearlyUrl: "https://app.gotradie.com.au/signup",
        button: "try for free",
         // Add tooltip content for the first item
        tooltipImage: "/images/animations/no-cash.gif",
        tooltipHeadline: "",
        tooltipText: "",

    },
    {
        // icon: "/images/icons/conversation-2.svg",
        // offer: "Most popular",
        title: "Starter",
        description: "up to 10 members",
        price: "$50",
        size: "100GB of storage",
        monthly: "per month",
        monthlyFrequency: "when billed monthly",
        yearlyFrequency: "when billed annually",
        business: "Per business",
        benefitOne: "Unlimited Chats & Messages",
        benefitTwo: "Unlimited Clips (200 slides)",
        benefitThree: "Unlimited To-do lists",
        benefitFour: "Unlimited Tasks",
        benefitFive: "Unlimited admin roles",
        benefitSix: "In-app, phone & email",
        benefitSeven: "Team Onboarding",
        monthlyUrl: "https://buy.stripe.com/eVadTy0Su7txabufZ6",
        yearlyUrl: "https://buy.stripe.com/eVa3eU7gS9BF6ZiaEN",
        button: "Buy now",
        tooltipImage: "",
        tooltipTextMonthly: "From the date you purchase your subscription, GoTradie will charge your card at the end of 30 days. Simply set & forget",
        tooltipTextYearly: "From the date you purchase your subscription, GoTradie will charge your card at the end of 365 days. Simply set & forget",
    },
//     {
//         // icon: "/images/icons/team-white.svg",
//         // offer: "Business Pro",
//         title: "Pro",
//         description: "up to 20 members",
//         price: "$100",
//         size: "250GB Storage included",
//         monthly: "per month",
//         monthlyFrequency: "when billed monthly",
//         yearlyFrequency: "when billed annually",
//         business: "Per business",
//         benefitOne: "Unlimited Chats & messages",
//         benefitTwo: "Unlimited Clips & Slides",
//         benefitThree: "Unlimited To-do lists",
//         benefitFour: "Unlimited Tasks",
//         benefitFive: "Unlimited admin roles",
//         benefitSix: "Dedicated account manager",
//         benefitSeven: "Team Onboarding",
//         monthlyUrl: "https://buy.stripe.com/dR62aQfNo3dhfvO9AH",
//         yearlyUrl: "https://buy.stripe.com/aEU6r644G9BF3N6bIL",
//         button: "buy now",
//         tooltipImage: "",
//         tooltipHeadline: "How will i be charged?",
//         tooltipText: "Easy use GoTradie for 30 days and we'll send you an invoice to pay at the end of the month",
//     },
// 
]
